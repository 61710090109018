import Header from "../composants/header/Header";
import { Footer } from "../composants/footer/Footer";
import back from "../resource/backgroundcon.jpg";
import Form from "../composants/formulaire/Form";
import FormTrack from "../composants/formulaire/FormTrack"
import con from "../resource/broche-de-localisation.png"

import React, { useRef } from "react";
import { MapContainer, TileLayer,Marker ,Tooltip } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


export default function Contact() {
        const mapRef = useRef(null);
        const latitude = 4.054403;
        const longitude = 9.695812;

        const myIcon = L.icon({
            iconUrl: con,
            iconSize: [40, 40],
            iconAnchor: [12, 41],
            popupAnchor: [0, -41]
          });
          

    return <div >
                <Header Background={back} logos = "Avoir confiance, c'est avancer ensemble"></Header>
               

                <div className="containall">
                    <div className="styleform">
                        <div className="form">
                            <Form></Form>
                                <div>
                                    < MapContainer center={[latitude, longitude]} zoom={18} ref={mapRef} className="stylemap" >
                                        <TileLayer
                                        url={`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=US&${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                                        subdomains={["mt0", "mt1", "mt2", "mt3"]}

                                        />
                                        {/* Additional map layers or components can be added here */}
                                    
                                            <Marker position={[latitude, longitude]} icon={myIcon} >
                                                    <Tooltip  permanent>
                                                        Bureau Matiasat Cameroun
                                                    </Tooltip >
                                            </Marker>                           
                                    </MapContainer>
                            </div>
                        </div>
                        <FormTrack></FormTrack>
                    </div>
                </div>

                <Footer></Footer>
    </div>
}