import './footer.css'
import logo from '../../resource/logom.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link,Button } from '@mui/material';
export function Footer() {
    

    return <>
            <div className="container">
                <div className="containeright">
                    <span>Liens rapide :</span>
                    <ul>
                        <li>
                            <Link href="/"  style={{color:'white'}}>  
                                Accueil
                            </Link>
                        </li>
                        <li> 
                            <Link href="/service"  style={{color:'white'}}>
                                Service
                            </Link>
                        </li>
                        <li>
                            <Link href="/produit"  style={{color:'white'}}>
                                Produit
                            </Link>
                        </li>
                        <li>
                             <Link href="/apropos"  style={{color:'white'}}>
                                A Propos 
                             </Link>
                        </li>
                        <li>
                            <Link href="/contact"  style={{color:'white'}}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                    
                </div>
                <div className="containercenter">
                <span>Contacts :</span>
                    <ul>
                        <li>
                            <LocationOnIcon ></LocationOnIcon>
                            <span> Akwa, près de l’immeuble ACTIVA</span>
                        </li>
                        <li> 
                            <EmailIcon></EmailIcon>
                              <span> info@matiasat.net</span>
                        </li>
                        <li>
                             <PhoneIcon></PhoneIcon>
                                <span>+237677707789</span>
                        </li>
                    </ul> 
                </div>
                <div className="containerleft">
                <Button style={{ display:"flex",justifyContent:"start"}} >
                    <img className='logo' href="/" src={logo} alt="logo" />
                </Button>
                <span>© 2024 Matiasat cameroun</span>
                </div>
            </div>
    </>
}
