import {Route,Routes,BrowserRouter} from "react-router-dom";



import Home from "./view/Home"
import Service from "./view/Service"
import About from "./view/About"
import Contact from "./view/Contact"
import Produit from "./view/Produit"



export default function Root(){

  return  <div>
        <BrowserRouter>
          <Routes>           
              <Route  path="/" element={<Home/>}></Route>
              <Route  path="/contact" element={<Contact/>}></Route>
              <Route  path="/apropos" element={<About/>}></Route>
              <Route  path="/service" element={<Service/>}></Route>
              <Route  path="/produit" element={<Produit/>}></Route>
          </Routes>
      </BrowserRouter>
        
</div>
};