import {  useState } from "react";
import {TextField,Button,CircularProgress,Alert, useMediaQuery} from "@mui/material/";
import $ from 'jquery'

const Form = () => {
  const [nom, setNom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sujet, setSujet] = useState("");
  const [spinner,setSpinner] = useState(false)
  

  const handleSubmit = (e) => {
    // Traiter les données du formulaire
    e.preventDefault();
    setSpinner(true)

    const fData = {nom,telephone, email, message,sujet}
      $.ajax({
        url : "https://" + window.location.hostname +"/http/index.php",
        method : "POST",
        data : fData,
        dataType:"json",
        error : function () {
          $('#result').css('display','block')
        },
        success : function(){
            setSpinner(false)
            setNom("")
            setSujet("")
            setTelephone("")
            setEmail("")
            setMessage("")
            
            $('#resultat').css('display','block')
            
            
        },
      }) 
      
  };
  const styleform = {
    display:"flex",
    flexDirection:"column",
    width:'500px',
    marginRight:'20px',
    padding:'50px'
  }
  const styleforms = {
    display:"flex",
    flexDirection:"column",
    width:'auto',
    marginRight:'0px',
    padding:'0px'
  }
  const Query = useMediaQuery('(max-width:900px)')

  return (
    <form onSubmit={handleSubmit} style={Query ? styleforms : styleform} method="POST">
      <TextField
        label="Nom & Prénom"
        variant="outlined"
        margin="normal"
        value={nom}
        onChange={(e) => setNom(e.target.value)}
        style={{ borderRadius: "10px" }}
        required
      />
      
      <TextField
        label="Téléphone"
        variant="outlined"
        margin="normal"
        value={telephone}
        onChange={(e) => setTelephone(e.target.value)}
        style={{ borderRadius: "10px" }}
        required
      />
      <TextField
        label="Email"
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderRadius: "10px" }}
        required
      />
      <TextField
        label="Objet"
        variant="outlined"
        margin="normal"
        value={sujet}
        onChange={(e) => setSujet(e.target.value)}
        style={{ borderRadius: "10px" }}
        required
      />
      <TextField
        label="Votre message"
        variant="outlined"
        multiline
        rows={4}
        margin="normal"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{ borderRadius: "10px", }}
      />
      
     <Alert id ="resultat" style={{display:'none'}}  severity="success">Votre message à bien été envoyer</Alert>
     <Alert id ="result" style={{display:'none'}} severity="error">Erreur au cours de l'envoie</Alert>
        {spinner === true ? <p style={{textAlign:"center"}} ><CircularProgress /> </p> : <Button  variant="contained" color="primary" type="submit" >Envoyer</Button>}
        
    </form>
    
  );
};

export default Form;