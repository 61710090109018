import { Footer } from "../composants/footer/Footer";
import Grids from "../composants/grid/Grids";
import back from '../resource/backproduit.png'
import back1 from '../resource/backabout.png'
import picture1 from '../resource/Picture1.png'
import picture2 from '../resource/Picture2.png'
import picture3 from '../resource/Picture3.png'
import picture4 from '../resource/Picture4.png'
import picture5 from '../resource/picture5.png'
import picture6 from '../resource/picture6.png'
import picture7 from '../resource/picture7.png'
import picture8 from '../resource/picture8.png'
import picture10 from '../resource/Picture9.png'
import picture11 from '../resource/Picture11.png'
import picture12 from '../resource/image-0.jpg'
import picture14 from '../resource/image-2.jpg'


import React from 'react';
import './style.css'
import'../resource/logom.png';
import logo from '../resource/logom.png'
import { styled, useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import BookIcon from '@mui/icons-material/Book';
import StoreIcon from '@mui/icons-material/Store';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';






const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


export default function Produit() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
    
  
    const  appbarstyle = {
      width: "100%",
      height: "100vh",
      boxShadow:'none',
      background: `url(${back})`,
      backgroundPositionX:'center',
      backgroundPositionY:' center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position:'initial'
      
    };

    const appbarstyles = {
      width: "100%",
      height: "100vh",
      boxShadow:'none',
      background: `url(${back1})`,
      backgroundPosition:'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position:'initial',

    }
  
    const logostyle = {
      height: 60,
      width: "auto",
      marginLeft: -8,
    }
  
    const logostyles = {
      height: 100,
      width: "auto",
      marginLeft: -8,
    }
  
    const matche = useMediaQuery('(max-width:360px)');
    const query = useMediaQuery('(max-width:880px)');
  
    const styleBack = {
      position:'absolute',
      top:'0',
      width:'100%'
    }
    
    const text1 = " GPS PADLOCK est non seulement un traceur GPS pour suivre le conteneur à distance (emplacement, vitesse, etc.), mais aussi un verrou électronique intelligent pour Contrôler la porte du conteneur, il sait que la porte du conteneur est ouverte par qui, où et quand.Ils ont été utilisées dans plus de 35 pays dans le cadre de projets gouvernementaux.15 594 unités ont été déployées en Zambie, au Congo et au Malawi par le COMESA pour la surveillance du transport de marchandises, 10 000 unités en République démocratique du Congo, plus de 8 000 unités auprès de fournisseurs agréés par la TRA (Tanzania Revenue Authority), plus de 5 500 unités auprès de fournisseurs agréés par la GRA (Ghana Revenue Authority) pour la surveillance du transit de marchandises sous douane, etc"
    const text2 = " GPS Padlock est configuré et fixer sur la serrure de votre Conteneur ceci en moins de 5min.A partie de ce moment vous pouvez tracker votre conteneur Sur la plateforme matiasat et recevoir des alertes en cas de Violation de la serrure ou de l’itinéraire prévu. Une fois la marchandise a bon pour vous pouvez ouvrir La serrure par sms ou par RFID."
  
    const text3 = "Le GH5200 est un tracker personnel autonome avec connectivité GNSS et GSM, conçu pour la protection des travailleurs isolés. Il permet la communication bidirectionnelle, dispose de boutons configurables pour divers scénarios d'alerte et est équipé de capteurs pour la détection de chute. Sa petite taille et son design confortable le rendent idéal pour une utilisation quotidienne en tant que porte-badge ou à la ceinture.Point fort de l'appareil : Communication bidirectionnelle Permet des appels et l'envoi de SMS en cas d'urgence, Détection de chute (Man-Down), Équipé d'une batterie de 1050 mAh pour une longue durée de fonctionnement, Protection IP30,Mémoire flash interne de 128MB "
    const text4 = "Le GH5200 est un traceur GPS autonome conçu pour la sécurité personnelle et la gestion de la main-d'œuvre, offrant une communication vocale bidirectionnelle et des boutons programmables pour des alertes et des actions rapides. Il intègre des scénarios de sécurité comme le bouton d'alarme et la détection de chute, est confortable à porter au quotidien grâce à sa conception mince, et utilise la technologie GSM/GPRS/GNSS/BLUETOOTH avec une batterie interne de 1050mAh et une mémoire flash de 128MB. Idéal pour les travailleurs isolés et les personnes vulnérables, le GH5200 assure une connectivité fiable et des fonctionnalités de sécurité avancées dans un dispositif portable et facile à utiliser."
    
    const text5 = "Le FMP100 est un tracker Plug and Play avec connectivité GNSS, GSM et Bluetooth, idéal pour le suivi de véhicules légers. Il se connecte facilement via l'allume-cigare et convient parfaitement aux services de livraison, location de voitures, et à la télémétrie.   "
    const text6 = "Le FMP100 est un traceur Plug and Play avec connectivité GNSS, GSM et Bluetooth®. Il est conçu pour une installation facile grâce à son connecteur d'alimentation pour allume-cigare."
    
    const text7 = "Ultrasonic Fuel Level Sensor est un détecteur de niveau de carburant du réservoir de camion et autres sans perforation. Associer à nos boitiers de tracking il donne la possibilité à nos utilisateurs de pouvoir visualiser leur consommation de carburant.les résultats des tests effectués avec le Capteur de Niveau de Carburant Ultrasonique chez Bolloré ont démontré une précision et une fiabilité exceptionnelles. Cette technologie avancée offre une mesure en temps réel du niveau de carburant, ce qui permet une gestion optimale des ressources et une réduction significative des coûts opérationnels."
    const text8 = "Le capteur émet des ondes sonores de haute fréquence, généralement entre 20 kHz et 200 kHz.Le capteur, situé au fond du réservoir, émet des ondes ultrasonores vers le haut. Les ondes se propagent à travers le carburant jusqu'à atteindre la surface supérieure, où elles sont réfléchies. L'écho des ondes réfléchies  est capté par le capteur. Le temps écoulé entre l'émission et la réception  de l'écho est mesuré. Connaissant la vitesse de propagation des ondes ultrasonores dans le carburant, la distance parcourue par les ondes peut être calculée. La hauteur du carburant est déterminée en soustrayant la distance mesurée depuis le fond du réservoir jusqu'à la surface du carburant de la hauteur totale du réservoir."
    
    const text9 = "La Dualcam est un dispositif de surveillance pour véhicules qui enregistre des séquences vidéo pendant la conduite. Elle sert à capturer des images en temps réel, offrant une preuve visuelle en cas d'incident sur la route. Ce modèle est équipé de fonctionnalités telles que l'enregistrement en boucle, la détection de mouvement . Elle est de plus en plus utilisées pour renforcer la sécurité routière et peut être particulièrement utiles pour documenter les conditions de conduite et les événements inattendus.Garantissant une précision de 99,5%, la surveillance du stationnement avec fonctionnalités de sécurité renforce votre sécurité et améliore votre conduite."
    const text10 = "Elle démarre automatiquement avec le moteur et enregistre en continu sur une carte mémoire. Les dashcams disposent souvent de fonctionnalités telles que l'enregistrement en boucle, qui écrase les anciennes séquences lorsque la carte est pleine, et la détection de mouvement, qui permet de filmer uniquement en cas d'activité. En cas d'accident, la vidéo peut être utilisée comme preuve, ce qui rend ces appareils particulièrement utiles pour les questions de sécurité et d'assurance."
    return ( <div>
        
        <Box sx={{ display: 'flex',  }} >
        
        <CssBaseline/>
        
        <AppBar color="transparent" position="fixed" open={open} style={ query ? appbarstyles :  appbarstyle } >
          
            <Toolbar style={{zIndex:1}}>
              
                <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                  <Button href="/">
                    <img src={logo} alt="matiasat cameroun" style={matche ? logostyle : logostyles}  /> 
                  </Button>
                </Typography>
            
               
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none'}) }}

                  style={{background:'black', width:50,height:50 }}
                >
                  <MenuIcon style={{color:'white'}} />
                </IconButton>
            </Toolbar>
             
            
              <video src={back} autoPlay loop muted style={styleBack}></video>              
          
        </AppBar>
        
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <CloseIcon/> : <ClearIcon/> }
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[{page :'Accueil',lien : '/'},  {page : 'Service',lien : '/service'},  {page : 'Produit',lien : '/produit'}, {page : 'Apropos', lien : '/apropos'},{page : 'Contact',lien : '/contact'}].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton href={text.lien}>
                  <ListItemIcon>
                    {index % 5 === 0 ? <HomeIcon /> : index % 5 === 1 ? <SettingsIcon /> : index % 5 === 2 ? <StoreIcon/> : index % 5 === 3 ? <EmailIcon/> : <BookIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={text.page} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> 
          <Divider/>
        </Drawer>
      </Box>    
            
    
    <div style={{marginTop:10}}>
      <Grids nom = {"GPS PADLOCK"} img1={picture1} img2={picture2} img3={picture3} text1={text1} text2={text2}></Grids>
      <Grids nom = {"GH5200"} img1={picture5}  img3={picture8} text1={text3} text2={text4}></Grids>
      <Grids nom = {"FMP100"} img1={picture6} img3={picture4} text1={text5} text2={text6}></Grids>
      <Grids nom = {"Ultrasonic Fuel Level Sensor"} img1={picture7} img2={picture11} img3={picture10} text1={text7} text2={text8}></Grids>
      <Grids nom = {"Dualcam"} img1={picture12}  img3={picture14} text1={text9} text2={text10} > </Grids>
    </div>
     
        <Footer></Footer>
    </div>
    )
       
}