
import './carte.css'
export default function carte ({titre,image,texte,id}){
return <>
    <div id={id} className="containercarte">
        <h1>{titre}</h1>
        <img src={image} alt="img1" srcset="" className='imgcarte' />
        <p>
            {texte}
        </p>
    </div>
</>
}