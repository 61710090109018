 import Header from "../composants/header/Header";
 import { Footer } from "../composants/footer/Footer";
 import back from "../resource/matiasat.mp4";

import Slide from "../composants/slider/Slide";
import Slider from "react-slick";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from "@mui/material";

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';


 export default function Accueil() {

    const listStyle = {
        listStyle:"none",
        padding:'0px'
    }
    const spanStyle = {
        display:'flex',
        flexDirection:'row',
    }
   
    var settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplaySpeed: 5000,
            cssEase: "linear",
             
           responsive: [
                {
                    breakpoint: 1020,
                    settings: {
                      dots: true,
                      infinite: true,
                      autoplay: true,
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      initialSlide: 1,
                      speed: 1000,
                      autoplaySpeed: 5000,
                      cssEase: "linear"
                    }
                  },
              {
                breakpoint: 600,
                settings: {
                  dots: true,
                  infinite: true,
                  autoplay: true,
                  adaptiveHeight: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  speed: 1000,
                  autoplaySpeed: 5000,
                  cssEase: "linear"
                }
              }
            ]
      };

    return <>
        <Header Background={back} logos = "Avoir confiance, c'est avancer ensemble" text=" Surveillez en toute confiance, avancez avec précision"></Header>
        <Slide></Slide>
        <div className="fond" >
            <h1 style={{textAlign:'center'}} >Les Fonctionnalités</h1>
            <div style={{backgroundColor:"ghostwhite"}} >
                <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',borderRadius:10}}> 
            
                <div className="colone"  >
                    <div style={{margin: 30}}>
                        <span style={spanStyle}>
                        Notre plateforme vous offre une facilité inégalée pour
                        suivre des objets, grâce à son interface conviviale et ergonomique, dotée de multiples
                        fonctionnalités.
                        </span>
                        <ul style={listStyle}>
                            <span style={spanStyle}>
                            <NavigateNextIcon></NavigateNextIcon>
                            <li>Géolocalisation</li>
                            </span>
                            <span style={spanStyle}>
                                <NavigateNextIcon></NavigateNextIcon>
                                <li>Gestion des évènements</li>
                            </span>
                            <span style={spanStyle}>
                                <NavigateNextIcon></NavigateNextIcon>
                                <li>Gestion de carburant</li>
                            </span>
                            <span style={spanStyle}>
                                <NavigateNextIcon></NavigateNextIcon>
                                <li>Notifications en temps réel</li>
                            </span>
                            <span style={spanStyle}>
                                <NavigateNextIcon></NavigateNextIcon>
                                <li>Historique et rapport</li>
                            </span>
                        </ul>
                        <div>
                            Vous pouvez utiliser le compte démo pour vous familiarisez.
                        </div>
                        <Button variant="contained" href="https://www.matiasat.net/index.php?demo=true">Compte démo</Button>
                    </div>
                    <div className="imgstyle" >
                      
                    </div>
                </div>
                </div>
            </div>
    </div>
    <div>
        <div className="containericons" style={{backgroundColor:'#1976d2',borderRadius:10,color:'white',padding:40}}>
            <h1 style={{textAlign:'center'}}> NOS ATOUTS</h1>
            <div className="icon1">
               <div className="blog1">
                    <div  style={{textAlign:"center",marginBottom:50}}>
                        <p><HomeRepairServiceIcon style={{color:"white"}} sx={{ fontSize: 30 }}></HomeRepairServiceIcon></p>
                        <p>Service de qualité</p>
                    </div>
                    <div  style={{textAlign:"center"}}>
                        <p><SupportAgentIcon style={{color:"white"}} sx={{ fontSize: 30 }}></SupportAgentIcon></p>
                        <p>Un service à l'écoute</p>
                    </div>
               </div>
                <div className="blog2">
                    <div style={{textAlign:"center",marginBottom:50}}>
                        <p ><ScaleOutlinedIcon style={{color:"white"}} sx={{ fontSize: 30 }}></ScaleOutlinedIcon></p>
                        <p> Une équipe de professionnelle</p>
                    </div>
                    <div style={{textAlign:"center"}}>
                        <p ><VerifiedOutlinedIcon style={{color:"white"}} sx={{ fontSize: 30 }}></VerifiedOutlinedIcon></p>
                        <p>Meilleurs rapport qualité/prix</p>
                    </div>
                </div>
                <div className="blog3">
                <div style={{textAlign:"center",marginBottom:50}}>
                    <p > <AutoAwesomeOutlinedIcon style={{color:"white"}} sx={{ fontSize: 30 }}></AutoAwesomeOutlinedIcon></p>
                    <p>Plateforme intuitive</p>
                </div>
                <div  style={{textAlign:"center"}}>
                    <p><HandymanOutlinedIcon style={{color:"white"}} sx={{ fontSize: 30 }}></HandymanOutlinedIcon></p>
                    <p>Matériel moderne</p>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div className="fonds" >
        <div className="containerimg">
            <div className="containerimg1">
                <div className="img1">
                    <h2>Pourquoi Nous</h2>
                    <p>
                        Leader camerounais de la géolocalisation et de l'IoT depuis plus de 15 ans, MATIASAT CAMEROUN propose une large gamme de solutions innovantes pour répondre aux besoins spécifiques de ses clients dans divers secteurs d'activité.
                        Les plus grands nous font confiance, il ne vous reste plus qu'à nous rejoindre.
                    </p>
                    <Button variant="contained" href="/contact"> Nous Contacter</Button>
                </div>
            </div>
            
            <div className="containerimg2">
                <div className="img2">
                    <h2>Démarrer avec nous</h2>
                    <p>
                        Nous avons créé un formulaire client sur mesure pour répondre à vos besoins spécifiques.
                        Ce formulaire vous permet de nous communiquer les informations essentielles sur votre
                        projet, vos attentes et vos préférences.
                    </p>
                    <Button variant="contained" href="/contact"> Nous Rejoindre </Button>
                </div>
            </div>
        </div>
        <h1 style={{textAlign:'center'}}>Témoignages</h1>
        <div className="slider-container" style={{height: 168}}>
      <Slider {...settings}>
        <div>
          <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',width: 300,height: 145, padding: 12, borderRadius:10}} >
            Entièrement satisfait des solutions de MATIASAT
            CAMEROUN. Efficacité, fiabilité et réactivité sont au rendez-vous. Je recommande vivement !
          </div>
        </div>
        <div>
          <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',width: 300,height: 145, padding: 12, borderRadius:10}}>
            Un grand merci à MATIASAT CAMEROUN pour son expertise et 
            son professionnalisme. Votre accompagnement a été précieux pour le développement de notre activité.
          </div>
        </div>
        <div>
          <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',width: 300,height: 145, padding: 12, borderRadius:10}}>
            Merci à MATIASAT CAMEROUN pour la qualité de ses services 
            et son engagement. Une équipe à l'écoute et toujours disponible pour répondre à nos besoins.
          </div>
        </div>
        <div>
          <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',width: 300,height: 145, padding: 12, borderRadius:10}}>
             Je fais confiance à MATIASAT CAMEROUN depuis plusieurs années et
             je n'ai jamais été déçu. Une entreprise sérieuse et fiable que je recommande sans hésiter.</div>
        </div>
      </Slider>
    </div>
    </div>    
    <Footer></Footer> 
    </>
}