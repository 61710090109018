
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";


import img1 from '../../resource/slider-imgA.png'
 import img2 from '../../resource/slider-imgB.png'
 import img3 from '../../resource/slider-imgC.png'
 import img4 from '../../resource/slider-imgD.png'
 import img5 from '../../resource/slider-imgE.png'
 import img6 from '../../resource/slider-imgF.png'
 import img7 from '../../resource/slider-imgG.png'
 import img8 from '../../resource/slider-imgH.png'
 import img9 from '../../resource/slider-imgI.png'
 import img10 from '../../resource/slider-imgJ.png'
 import img11 from '../../resource/slider-imgK.png'
 import img12 from '../../resource/slider-imgL.png'
 import logo from '../../resource/logom.png'
 import img13 from '../../resource/slider-stokak.png'



function AutoPlay() {
  const settings = {
   
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 15000,
    adaptiveHeight: true,
    centerMode: true,
    variableWidth: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          speed: 10000,
          slidesToScroll: 3,
          adaptiveHeight: true,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 10000,
          initialSlide: 2,
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          speed: 10000,
          adaptiveHeight: true,
          slidesToScroll: 1
        }
      }
    ]

  };
  const QUERY = useMediaQuery({maxWidth:1090})
 
 
  return (
    <div>
          { QUERY ? (
             <div className="slider-container" style={{background: 'transparent',position: 'relative', top: '-390px',zIndex: '1100',height:'0px'}} >
            
            <Slider {...settings}>
                
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img7})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 163,backgroundColor:'white',marginLeft:10,marginRight:10,height: 97, backgroundImage: `url(${img8})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 180,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img9})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 177,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img10})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 170,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img11})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 114,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img12})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${logo})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
            </Slider>
            <Slider {...settings} style={{marginTop:10}}>
                
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 159,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img1})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 137,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img2})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 112,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img3})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${logo})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 200,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img4})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img5})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 161,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img6})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 161,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img13})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
            </Slider>
        </div>
      ) : ( 
      <div className="slider-container" style={{background: 'transparent',position: 'relative', top: '-215px',zIndex: '1100'}} >
      <Slider {...settings}>
               
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img7})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${logo})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 163,backgroundColor:'white',marginLeft:10,marginRight:10,height: 97, backgroundImage: `url(${img8})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 180,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img9})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 177,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img10})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 170,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img11})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 114,backgroundColor:'white',marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img12})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
      </Slider>
      <Slider {...settings} style={{marginTop:10}}>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 220,backgroundColor:'white',marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${logo})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
          <div>
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 159,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img1})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 137,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img2})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 112,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img3})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 200,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img4})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:97, backgroundImage: `url(${img5})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 161,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img6})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
          <div >
            <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 161,marginLeft:10,marginRight:10, height: 97, backgroundImage: `url(${img13})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
          </div>
      </Slider>
  </div>
)}
      
</div> );
}

export default AutoPlay;
