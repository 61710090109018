import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';




export default function Grids({nom,img1,img2,img3,text1,text2}) {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
     const query = useMediaQuery('(max-width:900px)');
    return ( 
      <Container >
        <Box sx={{ width: '100%' }} >
            <Grid container   direction={ query ? 'column' : 'row' } rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 0 }}>
              <Grid item xs={6} >
                <Item style={{background:'#0f8cc2'}}>
                  <h1 style={{color:"white"}}> {nom}</h1>
                  <img src={img1} alt="" className="imgItem" />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item style={{ boxShadow:'none'}}   >
                  <h1>  Usage    </h1>
                  <Item style={{background:'#0f8cc2'}}>    </Item>
                  <Typography fontSize={20} textAlign='start'>
                    {text1}
                  </Typography>                          
                </Item>
              </Grid>
              <div>
                  <Grid>
                      <Item style={{ boxShadow:'none'}} >
                          <Typography fontSize={40}>
                              Fonctionnement
                          </Typography>
                          <img src={img2} alt="" srcset="" className="imgItem" />
                      </Item>
                  </Grid>
                  
                  <Grid direction={ query ? 'column' : 'row' }  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 0 }}>
                      <Grid item xs={6}>
                          <Item>
                              <img src={img3} alt="" srcset="" className="imgItem" />
                          </Item>
                      </Grid>
                      <Grid item xs={6}>
                          <Item style={{ boxShadow:'none'}} > 
                              <Typography fontSize={20} textAlign='start'>
                                {text2}
                              </Typography>
                          </Item>
                      </Grid>
                  </Grid>
              </div>
            </Grid>
          </Box>
      </Container>
          

)}