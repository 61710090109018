import React, { useState } from 'react';
import {useMediaQuery,FormGroup,CircularProgress  ,Radio, RadioGroup, FormControlLabel, Checkbox, FormControl, FormLabel, Select, MenuItem, Button } from '@mui/material';
import $ from 'jquery';
export default function FormTrack () {

       const root = {
          margin: '20px auto',
          width: '850px',
        }
        const roots = {
            margin: '0px',
            width: 'auto',
        }
        const space = useMediaQuery('(max-width:550px)');
 
  const [fleetSize, setFleetSize] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [features, setFeatures] = useState([]);
  const [companyType, setCompanyType] = useState('');
  const [spinner, setSpinner] = useState(false)
  

  const handleFleetSizeChange = (event) => {
    setFleetSize(event.target.value);
  };

  const handleVehicleTypeChange = (event) => {
    const newVehicleTypes = [...vehicleTypes];
    if (event.target.checked) {
      newVehicleTypes.push(event.target.value);
    } else {
      const index = newVehicleTypes.indexOf(event.target.value);
      newVehicleTypes.splice(index, 1);
    }
    setVehicleTypes(newVehicleTypes);
  };

  const handleFeatureChange = (event) => {
    const newFeatures = [...features];
    if (event.target.checked) {
      newFeatures.push(event.target.value);
    } else {
      const index = newFeatures.indexOf(event.target.value);
      newFeatures.splice(index, 1);
    }
    setFeatures(newFeatures);
  };

  const handleCompanyTypeChange = (event) => {
    setCompanyType(event.target.value);
  };

  
  $(document).ready(function () {
    $('#first').on('click', function () {
        $('#q2').css('display','block');
        $('#q1').css('display','none'); 
        $('#first').css('display','none');
      })
      $('#un').on('click', function () {
        $('#q2').css('display','none');
        $('#q1').css('display','block'); 
        $('#first').css('display','block');
      })
    $('#second').on('click', function () {
        $('#q3').css('display','block');
        $('#q2').css('display','none');
        $('#second').css('display','none');
      })
      $('#deux').on('click', function () {
        $('#q3').css('display','none');
        $('#q2').css('display','block');
        $('#second').css('display','block');
      })
    $('#trio').on('click', function () {
        $('#q4').css('display','flex');
        $('#q3').css('display','none');
        $('#trio').css('display','none');
      })
      $('#trois').on('click', function () {
        $('#q4').css('display','none');
        $('#q3').css('display','block');
        $('#trio').css('display','block');
      })
      
  })


  const handleSubmit = (event) => {
    event.preventDefault();
    // Envoyer les données du formulaire au serveur
    setSpinner(true)

    const fData = {fleetSize,vehicleTypes,features,companyType}
      $.ajax({
        url : "https://" + window.location.hostname +"/http/index.php",
        method : "POST",
        data : fData,
        dataType:"json",
        error : function () {
          alert("error")
        },
        success : function(){
            setSpinner(false)
            setFleetSize("")
            setVehicleTypes([])
            setFeatures([])
            setCompanyType("")
            $('#send').on('click',function () {
                $('#trois').css('display','none')        
              })
        },
      }) 

  };

  return (

    <form style={space ? roots : root} onSubmit={handleSubmit} method='POST'>
      <h2 style={{textAlign:'center'}}>Nous sommes à votre écoute pour recueillir plus de détails sur votre besoin et vous proposer la meilleure solution possible.</h2>
      <p>Veuillez répondre aux questions suivantes pour nous aider à mieux comprendre vos besoins en matière de suivi de flotte : </p>
        <div style={{display:'flex',flexDirection:"column" ,margin:10}}>
            <div id='q1' style={{display:'block'}}>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Quelle taille de flotte voulez-vous suivre ? </FormLabel>
                    <RadioGroup value={fleetSize} onChange={handleFleetSizeChange}>
                        <FormControlLabel value="1-10" control={<Radio />} label="1 à 5 véhicules" />
                        <FormControlLabel value="11-50" control={<Radio />} label="11 à 50 véhicules" />
                        <FormControlLabel value="51-99" control={<Radio />} label="51 à 99 véhicules" />
                        <FormControlLabel value="100+" control={<Radio />} label="100 véhicules et plus" />
                    </RadioGroup>
                    <Button variant="contained" color="primary" id='first' > Suivant </Button>
                </FormControl>
            </div>

            <div id="q2" style={{display:'none'}}>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Types de véhicules à suivre</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value="Voitures" onChange={handleVehicleTypeChange} />} label="voitures"/>
                        <FormControlLabel control={<Checkbox value="Camionnettes/camions" onChange={handleVehicleTypeChange} />} label="Camionnettes/camions" />
                        <FormControlLabel control={<Checkbox value="Motocycles" onChange={handleVehicleTypeChange} />} label="Motocycles" />
                        <FormControlLabel control={<Checkbox value="Machines de construction" onChange={handleVehicleTypeChange} />} label="Machines de construction" />
                        <FormControlLabel control={<Checkbox value="Autres" onChange={handleVehicleTypeChange} />} label="Autres" />
                    </FormGroup>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Button id='un'>Retour</Button>
                        <Button variant="contained" color="primary" id='second'>  Suivant  </Button>
                    </div>
                </FormControl>
            </div>

            <div id='q3' style={{display:'none'}}>
                <FormControl component="fieldset" required>
                    <FormLabel component="legend">Fonctionnalités souhaitées</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value="Planification des itinéraires" onChange={handleFeatureChange} />} label="Planification des itinéraires" />
                        <FormControlLabel control={<Checkbox value="Protection contre les vols" onChange={handleFeatureChange} />} label="Protection contre les vols" />
                        <FormControlLabel control={<Checkbox value="Enregistrement des temps" onChange={handleFeatureChange} />} label="Enregistrement des temps" />
                        <FormControlLabel control={<Checkbox value="Communication avec les conducteurs" onChange={handleFeatureChange} />} label="Communication avec les conducteurs" />
                        <FormControlLabel control={<Checkbox value="Téléchargement des données du tachygraphe numérique" onChange={handleFeatureChange} />} label="Téléchargement des données du tachygraphe numérique" />
                        <FormControlLabel control={<Checkbox value="Autre" onChange={handleFeatureChange} />} label="Autre" />
                    </FormGroup>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Button id='deux'>Retour</Button>
                        <Button variant="contained" color="primary" id='trio'> Suivant</Button>
                    </div>
                    
                </FormControl>
            </div>

            <div id='q4' style={{display:'none',}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <FormControl component="fieldset" required>
                        <FormLabel component="legend">Type d'entreprise</FormLabel>
                        <FormGroup>
                            <Select
                            labelId="company-type-label"
                            value={companyType}
                            onChange={handleCompanyTypeChange}
                            >
                                <MenuItem value="sarl">SARL</MenuItem>
                                <MenuItem value="entreprise_individuelle">Entreprise individuelle</MenuItem>
                                <MenuItem value="autre">Autre</MenuItem>
                            </Select>
                        </FormGroup>
                    </FormControl>
                    <div style={{display:'flex',justifyContent:'space-between'}}>  
                        <Button id='trois'>Retour</Button>
                        {spinner === true ? <p style={{textAlign:"center"}} ><CircularProgress /> </p> :  <Button variant="contained" color="primary" size="small" type="submit" id='send'> Envoyer le formulaire</Button>}                  
                    </div>
                </div>
            </div>
        </div>
    </form>
  );
};


     
          
