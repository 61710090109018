import Header from "../composants/header/Header";
import { Footer } from "../composants/footer/Footer";
import back from '../resource/backabout.png'
import img1 from '../resource/representation.jpg'
import img2 from '../resource/african.jpg'
import img3 from '../resource/young.jpg'

export default function Service() {
   
        return <>
            <Header Background={back}></Header>

            <div className="serviceall">
                <div className="containservice">
                   
                        <img src={img1} alt="" className="serviceimg"/>
                    
                    <div className="servicetext">
                        <h2>Géolocalisation et Gestion de flotte</h2>
                        <span>vous propose une solution de localisation et de suivi en temps réel pour une gestion optimisée de vos véhicules et de vos équipes.
                            Avec notre solution, vous pouvez :
                        </span>
                        <ul>
                            <li>1. Localiser en temps réel vos véhicules et vos équipes sur une carte interactive, 24h/24 et 7j/7.</li>
                            <li>2. Suivre le kilométrage parcouru par GPS et optimiser vos itinéraires.</li>
                            <li>3. Accéder à un historique complet des déplacements de vos véhicules et de vos équipes.</li>
                            <li>4. Recevoir des rapports automatiques par email sur l'activité de vos véhicules et de vos équipes.</li>
                            <li>5. Immobiliser vos véhicules à distance en cas de vol ou d'urgence.</li>
                        </ul>
                    </div>
                </div>

                <div className="containservice">
                    <div>
                        <img src={img3} alt="" srcset="" className="serviceimg"/>
                    </div>
                    <div className="servicetext">
                        <h2>Réseau Informatique</h2>
                        <span>
                            Le réseau informatique est un élément crucial du tracking de véhicule. 
                            Il permet de collecter, stocker et transmettre les données de localisation et d'autres informations relatives aux véhicules.
                            Le choix du réseau le plus adapté dépend de plusieurs facteurs, et plusieurs technologies peuvent être utilisées pour répondre aux besoins spécifiques de chaque application de suivi.
                        </span>
                        <ul>
                            <li>1. Couverture réseau</li>
                            <li>2. Précision des données</li>
                            <li>3. Budget</li>
                            <li>4. Fonctionnalités</li>
                            <li>5. Sécurité</li>
                        </ul>
                    </div>
                </div>

                <div className="containservice">
                    <div>
                        <img src={img2} alt="" srcset="" className="serviceimg"/>
                    </div>
                    <div className="servicetext">
                        <h2>Recherche Technologique et Innovation</h2>
                        <span>
                            Chez Matiasat Cameroun, nous sommes à la pointe de la recherche et de l'innovation dans le domaine du tracking. Notre équipe d'experts est constamment à la recherche de nouvelles technologies et de nouveaux moyens d'améliorer nos solutions de tracking afin de répondre aux besoins de nos clients.
                            Voici quelques exemples de nos initiatives en matière de recherche et d'innovation :
                        </span>
                        <ul>
                            <li>1. Amélioration de la satisfaction des clients</li>
                            <li>2. Développement de nouvelles technologies de tracking</li>
                            <li>3. Amélioration de l'analyse des données </li>
                            <li>4. Collaboration avec des partenaires</li>
                            <li>5. Amélioration de la sécurité du réseau</li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
}