import React from 'react';

import'./header.css';
import logo from '../../resource/logom.png'
import { styled, useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import BookIcon from '@mui/icons-material/Book';
import StoreIcon from '@mui/icons-material/Store';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Header({Background, logos,text}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  const  appbarstyle = {
    width: "100%",
    height: "100vh",
    boxShadow:'none',
    background: `url(${Background})`,
    backgroundPositionX:'center',
    backgroundPositionY:' -230px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position:'initial'
    
  };

  const logostyle = {
    height: 60,
    width: "auto",
    marginLeft: -8,
    borderRadius:10
  }

  const logostyles = {
    height: 100,
    width: "auto",
    marginLeft: -8,
    borderRadius:10
  }

  const matche = useMediaQuery('(max-width:360px)');

  const styleBack = {
    position:'absolute',
    top:'0',
    width:'100%'
  }

  const centertext = {
    backgroundColor: '#00000057',
    zIndex:1,
    fontSize:80,
    color:'white',
    textAlign:'center'
  }
  const centertexts = {
    backgroundColor: '#00000057',
    zIndex:1,
    fontSize:"150%",
    color:'white',
    textAlign:'center'
  }
  const matches = useMediaQuery('(max-width:1090px)');

  return (
  
      <Box sx={{ display: 'flex',  }} >
        
        <CssBaseline/>
        
        <AppBar color="transparent" position="fixed" open={open} style={ appbarstyle } >
          
            <Toolbar style={{zIndex:1}}>
              
                <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                  <Button href="/">
                    <img src={logo} alt="matiasat cameroun" style={matche ? logostyle : logostyles}  /> 
                  </Button>
                </Typography>
                <Button
                  href="https://www.matiasat.net/index.php"
                  style={{height:50,backgroundColor: 'rgb(25, 118, 210)',marginRight:20,color:"white",fontWeight:"600"}}
                >
                  Connexion
                </Button>
               
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: 'none'}) }}

                  style={{background:'black', width:50,height:50 }}
                >
                  <MenuIcon style={{color:'white'}} />
                </IconButton>
            </Toolbar>
            <Typography style={matches ? centertexts : centertext}  >
                 {text}
            </Typography>
            
              <video src={Background} autoPlay loop muted style={styleBack}></video>              
          
        </AppBar>
        
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <CloseIcon/> : <ClearIcon/> }
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[{page :'Accueil',lien : '/'},  {page : 'Service',lien : '/service'},  {page : 'Produit',lien : '/produit'}, {page : 'A propos', lien : '/apropos'},{page : 'Contact',lien : '/contact'}].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton href={text.lien}>
                  <ListItemIcon>
                    {index % 5 === 0 ? <HomeIcon /> : index % 5 === 1 ? <SettingsIcon /> : index % 5 === 2 ? <StoreIcon/> : index % 5 === 3 ? <EmailIcon/> : <BookIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={text.page} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> 
          <Divider/>
        </Drawer>
      </Box>    
    );
 
}
