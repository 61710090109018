import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import Header from "../composants/header/Header";
import Carte from "../composants/carte/Carte";
import { Footer } from "../composants/footer/Footer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import image  from "../resource/backabout.png"
import about1 from "../resource/about1.jpg"
import about2 from "../resource/about2.jpg"
import about3 from "../resource/about3.jpg"
import client from "../resource/client.jpg"



const liststyle = {
    backgroundColor:'ghostwhite',
    borderRadius:'10px', 
    height:"fit-content",
    margin:"20px",  
    zIndex: 1100,
}

const cartestyle = {
    backgroundColor:'ghostwhite',
    borderRadius:'10px',
    width:'50%'
}
const cartestyles = {
    backgroundColor:'ghostwhite',
    borderRadius:'10px',
    width:'100%'
}
const stylecontain = {
    padding:20,
    display:'flex',
    justifyContent:'center',
    width:'100%',
    position: 'relative',
    top: -295,
    zIndex: 1100,
}

const stylecontains = {
    padding:20,
    display:'flex',
    flexDirection:'column',
    width:'100%'

}


export default function Apropos() {

    const matches = useMediaQuery('(max-width:900px)');
    return <>
        
        <div style={{height:'400px'}}>
            <Header Background={image}></Header>
        </div>
        
        <div style={matches ? stylecontains : stylecontain}>
            <div style={liststyle}>
                <List >
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#lien1">
                        <ListItemText primary="Matiasat et son histoire" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#lien2">
                        <ListItemText primary="Notre clientèle" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#lien3">
                        <ListItemText primary="Notre savoir-faire technologique" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#lien4">
                        <ListItemText primary="Nos valeurs" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
            <div style={matches ? cartestyles : cartestyle}>
                <Carte id='lien1' titre={'Matiasat et son histoire'} image={about3} texte={"Matiasat Cameroun : Votre partenaire de confiance pour le suivi GPS.Présent depuis seulement 2 ans sur le marché camerounais, Matiasat Cameroun s'est rapidement imposé comme un leader dans le domaine du suivi GPS. Notre entreprise, basée à Douala, propose une large gamme de solutions de tracking innovantes et abordables pour répondre aux besoins de tous types de clients, des particuliers aux grandes entreprises."}></Carte>
                <Carte id='lien2' titre={'Notre clientèle'} image={about2} texte={"Parce que la sécurité de vos véhicules est une priorité hyperlocale, Matiasat Cameroun a développé une approche unique pour répondre aux besoins spécifiques de chaque quartier.Depuis des années, nous étudions les habitudes et les défis quotidiens des automobilistes, afin de proposer des solutions de tracking GPS parfaitement adaptées à chaque zone géographique.Qu'il s'agisse de zones urbaines denses ou de régions rurales isolées, Matiasat Cameroun vous offre une solution de suivi personnalisée qui répond à vos besoins spécifiques."}/>
                <Carte id='lien3' titre={'Notre savoir faire technologique'} image={client} texte={"Chez Matiasat Cameroun, nous sommes convaincus que la technologie est un élément essentiel pour garantir la sécurité et le suivi de vos véhicules. C'est pourquoi nous avons développé une plateforme innovante qui vous permet de : Suivre vos véhicules en temps réel, Recevoir des alertes en cas d'incident, Gérer vos flottes de véhicules, Analyser vos données de conduite. Notre plateforme est conçue pour être intuitive et facile à utiliser, que vous soyez un particulier ou un professionnel. Elle est accessible depuis n'importe quel appareil connecté à internet, vous permettant de garder un œil sur vos véhicules à tout moment.Nous utilisons les dernières technologies en matière de tracking GPS et de télématique pour vous offrir des solutions fiables et précises. Notre plateforme est constamment mise à jour avec de nouvelles fonctionnalités pour répondre à vos besoins en constante évolution."}/>
                <Carte id='lien4' titre={'Nos valeurs'} image={about1} texte={"Chez Matiasat Cameroun, nos valeurs fondamentales sont au cœur de tout ce que nous entreprenons. Elles éclairent nos décisions et définissent la manière dont nous collaborons avec nos clients, nos partenaires et nos équipes.Nos valeurs clés sont : l'innovation, l'intégrité, l'excellence, le respect et l'engagement"}/>

            </div>
        </div>
        
        <Footer> </Footer>

    </>
}